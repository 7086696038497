import mitt from 'mitt';

export const emitter = mitt();

/** @name 监控视图的屏幕模式 */
export const CAMETA_SCREEN_MODEL = 'camera-screen-model';
/** @name 视图hover查看 */
export const CAMETA_VIEW_SHOW = 'camera-view-show';
/** @name 编辑视图 */
export const CAMETA_EDIT = 'camera-edit';
/** @name 修改视图名称 */
export const CAMETA_EDIT_MODEL_NAME = 'camera-edit-model-name';

/** @name 监控视图模式 */
export const MODEL = {
  0: {
    label: 'MODEL_ONE',
    name: '单屏模式',
    length: 1,
  },
  1: {
    label: 'MODEL_FOUR',
    name: '四分屏模式',
    length: 4,
  },
  2: {
    label: 'MODEL_NINE',
    name: '九分屏模式',
    length: 9,
  },
  3: {
    label: 'MODEL_TWELVE',
    name: '十二分屏模式',
    length: 12,
  },
  4: {
    label: 'MODEL_MAIN',
    name: '焦点模式',
    length: 9,
  },
  5: {
    label: 'MODEL_LEFT',
    name: '主次模式1',
    length: 4,
  },
  6: {
    label: 'MODEL_RIGHT',
    name: '主次模式2',
    length: 4,
  },
};
