<template>
  <div
    ref="videoViewRef"
    :class="[
      $style.wrapVideo,
      isRecMode ? $style.showTimeline : $style.hideTimeline,
    ]"
  >
    <div
      :id="id"
      ref="videoDiv"
      @mouseenter="handleMouseenter"
      @mouseleave="handleMouseleave"
    ></div>
    <div
      v-if="closed"
      :class="[$style.btn, normal ? $style.normal : '']"
      @click.stop="handleClosed"
    >
      取消
    </div>
    <div
      v-if="showPantile"
      :class="[$style.ezPtzWrap]"
      id="ez-ptz-item"
      style="display: block;"
    >
      <div
        :class="[
          $style.ezPtzContainer,
          cameraDirection !== 4
            ? $style['ezPtzContainer' + cameraDirection]
            : '',
        ]"
        id="ez-ptz-container"
        ref="ptzContainer"
        @mousedown="
          e => {
            setDirection(e, 'start');
          }
        "
        @mouseup="
          e => {
            setDirection(e, 'stop');
          }
        "
      >
        <div :class="[$style.ezPtzMain, $style.center]"></div>
        <div :class="[$style.ezPtzIcon, $style.top, $style.active]"></div>
        <div :class="[$style.ezPtzIcon, $style.left, $style.active]"></div>
        <div :class="[$style.ezPtzIcon, $style.bottom, $style.active]"></div>
        <div :class="[$style.ezPtzIcon, $style.right, $style.active]"></div>
      </div>
    </div>
    <div
      :style="styleObject.wrapPlayer"
      :class="[
        $style.wrapPlayer,
        normal ? $style.normal : '',
        autoAction ? '' : $style.disabled,
      ]"
    >
      <span :style="styleObject.operation" :class="$style.operation">
        <!-- 播放/停止 -->
        <span :class="$style.iconBtn">
          <svg
            v-if="autoAction"
            @click="handleAutoAction"
            :width="base"
            :height="base"
            :fill="baseColor"
            viewBox="-6 -6 32 32"
          >
            <path
              id="Stroke-1"
              class="st1"
              d="M10.5,1.7c-4.9,0-8.8,4-8.8,8.8s4,8.8,8.8,8.8s8.8-4,8.8-8.8S15.4,1.7,10.5,1.7z M10.5,2.7
                c4.3,0,7.8,3.5,7.8,7.8s-3.5,7.8-7.8,7.8s-7.8-3.5-7.8-7.8S6.2,2.7,10.5,2.7z"
            />
            <path
              class="st2"
              d="M8.7,8C9,8,9.3,8.3,9.3,8.6v3.8C9.3,12.7,9,13,8.7,13C8.3,13,8,12.7,8,12.4V8.6C8,8.3,8.3,8,8.7,8z"
            />
            <path
              id="Rectangle-Copy-10"
              class="st2"
              d="M12.8,8c0.3,0,0.6,0.3,0.6,0.6v3.8c0,0.3-0.3,0.6-0.6,0.6c-0.3,0-0.6-0.3-0.6-0.6V8.6
                C12.2,8.3,12.5,8,12.8,8z"
            />
          </svg>
          <svg
            v-else
            @click="handleAutoAction"
            :width="base"
            :height="base"
            :fill="baseColor"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-6 -6 32 32"
          >
            <path
              d="M13,9.8L10.1,8C9.9,7.9,9.7,7.9,9.5,7.9c-0.6,0-1,0.4-1,1v3.7c0,0.2,0.1,0.4,0.2,0.5c0.3,0.5,0.9,0.6,1.4,0.3 l2.9-1.8c0.1-0.1,0.2-0.2,0.3-0.3C13.6,10.7,13.4,10.1,13,9.8z"
            />
            <path
              d="M10.5,1.9c-4.9,0-8.8,4-8.8,8.8c0,4.9,4,8.8,8.8,8.8s8.8-4,8.8-8.8C19.4,5.8,15.4,1.9,10.5,1.9z M10.5,18.5 c-4.3,0-7.8-3.5-7.8-7.8s3.5-7.8,7.8-7.8c4.3,0,7.8,3.5,7.8,7.8S14.9,18.5,10.5,18.5z"
            />
          </svg>
        </span>
        <!-- 开启/静音 -->
        <span :class="$style.iconBtn">
          <svg
            @click="handleSoundChange"
            v-if="!sound"
            :width="base"
            :height="base"
            :fill="baseColor"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-6 -6 32 32"
          >
            <path
              d="M8.5,4.2c0.8-0.7,2.1-0.2,2.2,0.9l0,0.1v10c0,1.1-1.2,1.7-2.1,1.1l-0.1-0.1l-3.3-2.8C5,13.2,5,12.9,5.1,12.7 c0.2-0.2,0.4-0.2,0.6-0.1l0.1,0.1l3.3,2.8c0.2,0.2,0.5,0.1,0.5-0.2l0-0.1v-10c0-0.3-0.3-0.4-0.5-0.3L9.2,5L5.9,7.8 C5.6,7.9,5.3,7.9,5.1,7.7C5,7.5,5,7.3,5.1,7.1L5.2,7L8.5,4.2z"
            />
            <path
              d="M5.5,6.9C5.8,6.9,6,7.1,6,7.4c0,0.2-0.2,0.4-0.4,0.5l-0.1,0h-2C3.4,7.9,3.3,8,3.2,8.2l0,0.1v4 c0,0.2,0.1,0.3,0.3,0.3l0.1,0h2C5.8,12.5,6,12.7,6,13c0,0.2-0.2,0.4-0.4,0.5l-0.1,0h-2c-0.7,0-1.3-0.5-1.3-1.2l0-0.1v-4 c0-0.7,0.5-1.3,1.2-1.3l0.1,0H5.5z"
            />
            <path
              d="M17.4,7.9c0.2-0.2,0.5-0.2,0.7,0c0.2,0.2,0.2,0.4,0.1,0.6l-0.1,0.1l-3.8,3.8c-0.2,0.2-0.5,0.2-0.7,0 c-0.2-0.2-0.2-0.4-0.1-0.6l0.1-0.1L17.4,7.9z"
            />
            <path
              d="M13.7,7.9c0.2-0.2,0.4-0.2,0.6-0.1l0.1,0.1l3.8,3.8c0.2,0.2,0.2,0.5,0,0.7c-0.2,0.2-0.4,0.2-0.6,0.1l-0.1-0.1 l-3.7-3.8C13.5,8.4,13.5,8.1,13.7,7.9z"
            />
          </svg>
          <svg
            @click="handleSoundChange"
            v-else
            :width="base"
            :height="base"
            :fill="baseColor"
            viewBox="-6 -6 32 32"
          >
            <path
              d="M13.2,7.1c0.1-0.2,0.5-0.3,0.7-0.2c1.1,0.7,1.9,2.2,1.9,3.7c0,1.6-0.7,3-1.9,3.7
                c-0.2,0.1-0.5,0.1-0.7-0.2c-0.1-0.2-0.1-0.5,0.2-0.7c0.8-0.5,1.4-1.6,1.4-2.9c0-1.3-0.6-2.4-1.4-2.9C13.1,7.6,13,7.3,13.2,7.1z"
            />
            <path
              d="M15.7,4.5c0.2-0.2,0.5-0.2,0.7-0.1C18,5.8,19,8.2,19,10.7c0,2.5-1,4.8-2.7,6.3
                c-0.2,0.2-0.5,0.2-0.7-0.1c-0.2-0.2-0.2-0.5,0.1-0.7c1.4-1.2,2.3-3.3,2.3-5.5c0-2.2-0.9-4.3-2.3-5.5C15.5,5,15.5,4.7,15.7,4.5z"
            />
            <path
              id="Stroke-5"
              class="st1"
              d="M8.5,4.7c0.8-0.7,2.1-0.2,2.2,0.9l0,0.1v10c0,1.1-1.2,1.7-2.1,1.1l-0.1-0.1l-3.3-2.8
                C5,13.7,5,13.4,5.1,13.2c0.2-0.2,0.4-0.2,0.6-0.1l0.1,0.1l3.3,2.8c0.2,0.2,0.5,0.1,0.5-0.2l0-0.1v-10c0-0.3-0.3-0.4-0.5-0.3l-0.1,0
                L5.9,8.3C5.6,8.4,5.3,8.4,5.1,8.2C5,8,5,7.7,5.1,7.6l0.1-0.1L8.5,4.7z"
            />
            <path
              d="M5.5,7.4C5.8,7.4,6,7.6,6,7.9c0,0.2-0.2,0.4-0.4,0.5l-0.1,0h-2c-0.2,0-0.3,0.1-0.3,0.3l0,0.1v4
                c0,0.2,0.1,0.3,0.3,0.3l0.1,0h2C5.8,13,6,13.2,6,13.5c0,0.2-0.2,0.4-0.4,0.5l-0.1,0h-2c-0.7,0-1.3-0.5-1.3-1.2l0-0.1v-4
                c0-0.7,0.5-1.3,1.2-1.3l0.1,0H5.5z"
            />
          </svg>
        </span>
      </span>
      <span :style="styleObject.operation" :class="$style.operation">
        <span id="pantile" :class="$style.iconBtn" v-if="isPantile">
          <svg
            @click="handlePantile"
            :width="base"
            :height="base"
            :fill="baseColor"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-6 -6 32 32"
          >
            <path
              d="M10.2,7.8c1.6,0,2.9,1.3,2.9,2.9s-1.3,2.9-2.9,2.9s-2.9-1.3-2.9-2.9S8.5,7.8,10.2,7.8z M10.2,8.8c-1.1,0-1.9,0.9-1.9,1.9s0.9,1.9,1.9,1.9s1.9-0.9,1.9-1.9S11.2,8.8,10.2,8.8z"
            ></path>
            <path
              d="M8.8,3.5c0.7-0.6,1.8-0.6,2.5-0.1l0.1,0.1l1.4,1.1c0.2,0.2,0.3,0.5,0.1,0.7 c-0.2,0.2-0.4,0.2-0.6,0.1l-0.1,0l-1.4-1.1C10.5,3.9,10,3.9,9.6,4.2L9.4,4.3L8,5.4C7.8,5.5,7.5,5.5,7.3,5.3c-0.2-0.2-0.1-0.5,0-0.6 l0.1-0.1L8.8,3.5z"
            ></path>
            <path
              d="M2.5,12.3c-0.6-0.7-0.6-1.8-0.1-2.5l0.1-0.1l1.1-1.4c0.2-0.2,0.5-0.3,0.7-0.1 c0.2,0.2,0.2,0.4,0.1,0.6l0,0.1l-1.1,1.4C3,10.6,3,11.1,3.2,11.5l0.1,0.1L4.4,13c0.2,0.2,0.1,0.5-0.1,0.7c-0.2,0.2-0.5,0.1-0.6,0 l-0.1-0.1L2.5,12.3z"
            ></path>
            <path
              d="M17.7,12.3c0.6-0.7,0.6-1.8,0.1-2.5l-0.1-0.1l-1.1-1.4c-0.2-0.2-0.5-0.3-0.7-0.1 c-0.2,0.2-0.2,0.4-0.1,0.6l0,0.1l1.1,1.4c0.3,0.4,0.3,0.9,0.1,1.3l-0.1,0.1L15.8,13c-0.2,0.2-0.1,0.5,0.1,0.7c0.2,0.2,0.5,0.1,0.6,0 l0.1-0.1L17.7,12.3z"
            ></path>
            <path
              d="M8.8,18.2c0.7,0.6,1.8,0.6,2.5,0.1l0.1-0.1l1.4-1.1c0.2-0.2,0.3-0.5,0.1-0.7 c-0.2-0.2-0.4-0.2-0.6-0.1l-0.1,0l-1.4,1.1c-0.4,0.3-0.9,0.3-1.3,0.1l-0.1-0.1L8,16.3c-0.2-0.2-0.5-0.1-0.7,0.1 c-0.2,0.2-0.1,0.5,0,0.6l0.1,0.1L8.8,18.2z"
            ></path>
          </svg>
        </span>
        <!-- 标清（SD）和高清（HD） -->
        <span :class="$style.iconBtn">
          <svg
            @click="handleActionHDORSD"
            v-if="!boolIsSD"
            :width="base"
            :height="base"
            :fill="baseColor"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-6 -6 32 32"
          >
            <path
              d="M17.4,16.5H3.1c-0.8,0-1.4-0.6-1.4-1.4V5.4c0-0.9,0.7-1.6,1.6-1.6h14.1c0.8,0,1.4,0.6,1.4,1.4v9.8 C18.8,15.9,18.2,16.5,17.4,16.5z M3.3,5C3.1,5,2.9,5.2,2.9,5.4v9.7c0,0.2,0.1,0.3,0.3,0.3h14.3c0.2,0,0.3-0.1,0.3-0.3V5.3 c0-0.2-0.1-0.3-0.3-0.3H3.3z"
            />
            <path
              d="M13.3,13.6h-1.6c-0.4,0-0.7-0.3-0.7-0.7V7.4c0-0.4,0.3-0.7,0.7-0.7h1.6c1.2,0,2.2,1,2.2,2.2v2.4 C15.6,12.6,14.6,13.6,13.3,13.6z M12.2,12.5h1.1c0.6,0,1.1-0.5,1.1-1.1V9c0-0.6-0.5-1.1-1.1-1.1h-1.1V12.5z"
            />
            <path
              d="M5.5,13.6c-0.3,0-0.6-0.2-0.6-0.6V7.3C5,7,5.2,6.8,5.5,6.8S6.1,7,6.1,7.3v5.7C6.1,13.4,5.8,13.6,5.5,13.6z"
            />
            <path
              d="M9.2,13.6c-0.3,0-0.6-0.2-0.6-0.6V7.3c0-0.3,0.2-0.6,0.6-0.6S9.8,7,9.8,7.3v5.7C9.8,13.4,9.5,13.6,9.2,13.6z"
            />
            <rect x="5.6" y="9.6" width="3.6" height="1.1" />
          </svg>
          <svg
            @click="handleActionHDORSD"
            v-else
            :width="base"
            :height="base"
            :fill="baseColor"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-6 -8 40 44"
          >
            <path
              d="M24.1,23.8h-20c-1.1,0-1.9-0.9-1.9-1.9V8.4c0-1.2,1-2.2,2.1-2.2h19.7c1.1,0,1.9,0.9,1.9,1.9v13.8
                  C26,23,25.1,23.8,24.1,23.8z M4.3,7.7C4,7.7,3.7,8,3.7,8.4v13.5c0,0.2,0.2,0.4,0.4,0.4h20c0.2,0,0.4-0.2,0.4-0.4V8.2
                  c0-0.2-0.2-0.4-0.4-0.4H4.3z"
            />
            <path
              d="M18.4,19.8h-2.2c-0.5,0-0.9-0.4-0.9-0.9v-7.8c0-0.5,0.4-0.9,0.9-0.9h2.2c1.7,0,3.1,1.4,3.1,3.1v3.3
              C21.5,18.4,20.1,19.8,18.4,19.8z M16.7,18.3h1.6c0.9,0,1.6-0.7,1.6-1.6v-3.3c0-0.9-0.7-1.6-1.6-1.6h-1.6V18.3z"
            />
            <path
              d="M10.5,19.8c1.2,0,2.1-0.3,2.7-0.9c0.6-0.6,0.9-1.3,0.9-2.1c0-0.8-0.3-1.4-0.9-1.8c-0.4-0.2-1.1-0.5-2.2-0.8
                  l0,0l-1-0.2c-0.4-0.1-0.8-0.2-1-0.4c-0.4-0.2-0.6-0.5-0.6-0.9c0-0.4,0.1-0.6,0.4-0.9s0.7-0.3,1.3-0.3c0.8,0,1.4,0.2,1.8,0.6
                  c0.2,0.3,0.3,0.6,0.4,0.9l0,0h1.4c0-0.6-0.2-1.1-0.5-1.6c-0.6-0.8-1.6-1.2-2.9-1.2c-1,0-1.8,0.3-2.4,0.8c-0.6,0.5-0.9,1.2-0.9,2
                  c0,0.7,0.3,1.3,1,1.7c0.4,0.2,0.9,0.4,1.7,0.6l0,0l1.2,0.3c0.6,0.2,1.1,0.3,1.3,0.4c0.3,0.2,0.5,0.5,0.5,0.9c0,0.5-0.2,0.9-0.6,1.1
                  s-0.9,0.4-1.5,0.4c-0.9,0-1.6-0.2-2-0.7c-0.2-0.3-0.3-0.6-0.4-1.1l0,0H6.8c0,0.9,0.3,1.6,0.9,2.2C8.2,19.5,9.2,19.8,10.5,19.8z"
            />
            <defs>
              <filter
                id="Adobe_OpacityMaskFilter"
                filterUnits="userSpaceOnUse"
                x="15.2"
                y="10.3"
                width="6.2"
                height="9.5"
              >
                <feColorMatrix
                  type="matrix"
                  values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
                />
              </filter>
            </defs>
            <mask
              maskUnits="userSpaceOnUse"
              x="15.2"
              y="10.3"
              width="6.2"
              height="9.5"
              id="mask-2_2_"
            >
              <g class="st2">
                <path
                  id="path-1_2_"
                  class="st3"
                  d="M24.1,23.1h-20c-0.6,0-1.2-0.5-1.2-1.2V8.2C2.9,7.5,3.5,7,4.1,7h19.7c0.8,0,1.4,0.6,1.4,1.4
                    v13.5C25.2,22.6,24.7,23.1,24.1,23.1z"
                />
              </g>
            </mask>
            <defs>
              <filter
                id="Adobe_OpacityMaskFilter_1_"
                filterUnits="userSpaceOnUse"
                x="6.8"
                y="10.3"
                width="7.3"
                height="9.5"
              >
                <feColorMatrix
                  type="matrix"
                  values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
                />
              </filter>
            </defs>
            <mask
              maskUnits="userSpaceOnUse"
              x="6.8"
              y="10.3"
              width="7.3"
              height="9.5"
              id="mask-2_3_"
            >
              <g class="st5">
                <path
                  id="path-1_3_"
                  class="st3"
                  d="M24.1,23.1h-20c-0.6,0-1.2-0.5-1.2-1.2V8.2C2.9,7.5,3.5,7,4.1,7h19.7c0.8,0,1.4,0.6,1.4,1.4
                    v13.5C25.2,22.6,24.7,23.1,24.1,23.1z"
                />
              </g>
            </mask>
          </svg>
        </span>
        <!-- 截图 -->
        <span :class="$style.iconBtn">
          <svg
            @click="handleCapturePicture"
            :width="base"
            :height="base"
            :fill="baseColor"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-6 -6 32 32"
          >
            <path
              d="M10.8,7.7c-2,0-3.7,1.6-3.7,3.7S8.7,15,10.8,15c2,0,3.7-1.6,3.7-3.7S12.8,7.7,10.8,7.7z M10.8,8.7c1.5,0,2.7,1.2,2.7,2.7S12.2,14,10.8,14c-1.5,0-2.7-1.2-2.7-2.7S9.3,8.7,10.8,8.7z"
            />
            <path
              d="M8.6,3.7l-0.1,0C8,3.7,7.7,4,7.5,4.3l-1,1.7l-1.3,0C4,6.1,3.1,7,3.1,8.2v7.1 c0,1.2,0.9,2.1,2.1,2.1h11.1c1.2,0,2.1-0.9,2.1-2.1V8.2l0-0.1c-0.1-1.1-1-1.9-2.1-1.9l-1.3,0l-1.1-1.8c-0.2-0.4-0.7-0.6-1.1-0.6H8.6 z M8.6,4.7h4.2c0.1,0,0.2,0.1,0.3,0.1l1.2,2c0.1,0.2,0.3,0.2,0.4,0.2h1.6c0.6,0,1.1,0.5,1.1,1.1v7.1c0,0.6-0.5,1.1-1.1,1.1H5.1 c-0.6,0-1.1-0.5-1.1-1.1V8.2c0-0.6,0.5-1.1,1.1-1.1h1.6c0.2,0,0.3-0.1,0.4-0.2l1.2-2C8.4,4.7,8.5,4.7,8.6,4.7z"
            />
          </svg>
        </span>
        <!-- 录屏 -->
        <span :class="$style.iconBtn">
          <svg
            @click="handleActionSaveChange"
            :width="base"
            :height="base"
            :fill="actionSave ? baseRedColor : baseColor"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-6 -6 32 32"
          >
            <path
              d="M11.6,5.3H4.7c-1.4,0-2.5,1.1-2.5,2.5v5.9c0,1.4,1.1,2.5,2.5,2.5h6.9c1.4,0,2.5-1.1,2.5-2.5V7.7 C14.1,6.4,13,5.3,11.6,5.3z M4.7,6.3h6.9c0.8,0,1.5,0.7,1.5,1.5v5.9c0,0.8-0.7,1.5-1.5,1.5H4.7c-0.8,0-1.5-0.7-1.5-1.5V7.7 C3.3,6.9,3.9,6.3,4.7,6.3z"
            />
            <path
              d="M16.6,6.7c0.9-0.8,2.3-0.1,2.4,1l0,0.1v5.7c0,1.2-1.3,1.9-2.3,1.2l-0.1-0.1L13.3,12 c-0.2-0.2-0.2-0.5-0.1-0.7c0.2-0.2,0.4-0.2,0.6-0.1l0.1,0.1l3.3,2.7c0.3,0.2,0.7,0.1,0.8-0.3l0-0.1V7.8c0-0.4-0.4-0.6-0.7-0.4 l-0.1,0l-3.3,2.7c-0.2,0.2-0.5,0.1-0.7-0.1c-0.2-0.2-0.1-0.5,0-0.6l0.1-0.1L16.6,6.7z"
            />
          </svg>
        </span>
        <template v-if="enableBack">
          <span :class="$style.iconBtn" v-if="!isRecMode">
            <svg
              @click="handleModeChange"
              t="1578020072465"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="4154"
              width="24"
              height="24"
            >
              <path
                d="M421.934545 707.258182L719.592727 537.6c9.541818-5.12 13.032727-17.221818 7.912728-26.763636-1.861818-3.258182-4.421818-6.050909-7.912728-7.912728l-297.890909-169.192727c-9.774545-5.585455-22.109091-2.327273-27.694545 7.447273-1.861818 3.025455-2.792727 6.516364-2.792728 10.007273v338.385454c0.232727 11.403636 9.541818 20.48 20.945455 20.247273 3.490909 0 6.749091-0.930909 9.774545-2.56z m88.669091-645.12c-128-0.232727-250.414545 52.130909-338.618181 144.756363-6.050909 6.050909-12.101818 12.567273-17.687273 19.549091l-92.16-90.763636v248.785455h252.509091l-94.254546-92.625455c71.214545-87.505455 177.803636-138.007273 290.676364-137.541818 202.007273 3.025455 363.054545 169.425455 360.029091 371.432727-3.025455 202.007273-169.425455 363.054545-371.432727 360.029091-154.530909-2.327273-290.676364-101.701818-340.48-247.854545H62.370909c61.672727 248.785455 313.250909 400.290909 561.803636 338.618181s400.290909-313.250909 338.618182-561.803636C911.36 206.894545 724.712727 61.207273 510.603636 62.138182z"
                p-id="4155"
                fill="#ffffff"
              ></path>
            </svg>
          </span>
          <!-- 回放 -->
          <span :class="$style.iconBtn" v-else>
            <svg
              @click="handleModeChange"
              t="1657175234838"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="5284"
              width="32"
              height="32"
            >
              <path
                d="M255.573333 210.304a32 32 0 0 1 0 45.226667 362.666667 362.666667 0 0 0 0 512.896 32 32 0 0 1-45.269333 45.269333c-166.613333-166.613333-166.613333-436.778667 0-603.392a32 32 0 0 1 45.226667 0z m558.122667 0c166.613333 166.613333 166.613333 436.778667 0 603.392a32 32 0 0 1-45.226667-45.226667 362.666667 362.666667 0 0 0 0-512.896 32 32 0 1 1 45.226667-45.269333zM376.234667 330.965333a32 32 0 0 1 0 45.269334 192 192 0 0 0 0 271.530666 32 32 0 0 1-45.226667 45.226667 256 256 0 0 1 0-362.026667 32 32 0 0 1 45.226667 0z m316.8 0a256 256 0 0 1 0 362.069334 32 32 0 1 1-45.269334-45.269334 192 192 0 0 0 0-271.530666 32 32 0 0 1 45.226667-45.226667zM512 448a64 64 0 1 1 0 128 64 64 0 0 1 0-128z"
                p-id="5285"
                fill="#ffffff"
              ></path>
            </svg>
          </span>
        </template>
        <!-- 放大 -->
        <!-- <i
            class="TC tc-icon-sousuofangda icon"
            :class="[normal ? 'normal' : '', actionZoom ? 'red' : '']"
            @click="handleActionZoomChange"
          />-->
        <!-- 全屏 -->
        <span :class="$style.iconBtn">
          <svg
            @click="handleFullScreen"
            v-if="fullScreenStatus === 0"
            :width="base"
            :height="base"
            :fill="baseColor"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-6 -6 32 32"
          >
            <path
              d="M3.4,7.6c-0.3,0-0.5-0.2-0.5-0.5V5.3c0-1.2,1-2.3,2.2-2.3h1.8c0.3,0,0.5,0.2,0.5,0.5S7.2,4.1,6.9,4.1H5.2 c-0.7,0-1.2,0.6-1.2,1.3v1.8C3.9,7.4,3.7,7.6,3.4,7.6z"
            />
            <path
              d="M6.9,18.1H5.2c-1.2,0-2.2-1-2.2-2.2v-1.8c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5v1.8c0,0.7,0.6,1.2,1.2,1.2 h1.8c0.3,0,0.5,0.2,0.5,0.5S7.2,18.1,6.9,18.1z"
            />
            <path
              d="M15.7,18.1h-1.8c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5h1.8c0.7,0,1.2-0.6,1.2-1.2v-1.8 c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5v1.8C17.9,17.1,16.9,18.1,15.7,18.1z"
            />
            <path
              d="M17.4,7.6c-0.3,0-0.5-0.2-0.5-0.5V5.3c0-0.7-0.6-1.3-1.2-1.3h-1.8c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5h1.8 c1.2,0,2.2,1,2.2,2.3v1.8C17.9,7.4,17.7,7.6,17.4,7.6z"
            />
          </svg>

          <svg
            @click="handleFullScreen"
            v-else
            :width="base"
            :height="base"
            :fill="baseColor"
            viewBox="-6 -6 32 32"
          >
            <path
              class="st1"
              d="M5.7,8.1H3.9c-0.3,0-0.6-0.2-0.6-0.6S3.6,7,3.9,7h1.9c0.7,0,1.3-0.6,1.3-1.3V3.8c0-0.3,0.2-0.6,0.6-0.6
                s0.6,0.2,0.6,0.6v1.9C8.2,7,7.1,8.1,5.7,8.1z"
            />
            <path
              class="st1"
              d="M7.6,17.7c-0.3,0-0.6-0.2-0.6-0.6v-1.9c0-0.7-0.6-1.3-1.3-1.3H3.9c-0.3,0-0.6-0.2-0.6-0.6s0.2-0.6,0.6-0.6h1.9
                c1.3,0,2.4,1.1,2.4,2.4v1.9C8.2,17.5,7.9,17.7,7.6,17.7z"
            />
            <path
              class="st1"
              d="M13.4,17.7c-0.3,0-0.6-0.2-0.6-0.6v-1.9c0-1.3,1.1-2.4,2.4-2.4h1.9c0.3,0,0.6,0.2,0.6,0.6S17.5,14,17.2,14
                h-1.9c-0.7,0-1.3,0.6-1.3,1.3v1.9C14,17.5,13.8,17.7,13.4,17.7z"
            />
            <path
              class="st1"
              d="M17.2,8.1h-1.9c-1.3,0-2.4-1.1-2.4-2.4V3.8c0-0.3,0.2-0.6,0.6-0.6S14,3.5,14,3.8v1.9C14,6.4,14.6,7,15.3,7h1.9
                c0.3,0,0.6,0.2,0.6,0.6S17.5,8.1,17.2,8.1z"
            />
          </svg>
        </span>
      </span>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import EZUIKit from 'ezuikit-js';
import { throttle } from 'lodash';
import {
  emitter,
  CAMETA_VIEW_SHOW,
} from '@/views/iot/camera-view/components/edit/final.js';
import screenfull from '@/utils/fullscreen';
import { delay } from '@triascloud/utils';
import { refreshAccessToken } from '@/services/monitor/api';
/**
 * TODO 焦点模式下，全屏操作了放大，导致正常屏幕，video视图变大问题？
 */
@Component()
export default class videoView extends Vue {
  @Prop({
    required: true,
    type: String,
    default: 'at.6wz0zt03d4tifanxdmh44qgn7o47q41t-968n2v5ib7-00xwbtq-foadirsso',
  })
  accessToken;
  @Prop({
    required: true,
    type: String,
    default: 'ezopen://WSDRAB@open.ys7.com/F30203581/1.hd.live',
  })
  url;
  @Prop({ type: Object, default: () => {} }) option;
  @Prop({ type: Object, default: () => {} }) data;
  @Prop({ type: Boolean, default: false }) enableBack;
  /** @name style */
  @Prop({
    type: Object,
    default: () => {
      return {
        wrapPlayer: {},
        operation: {},
      };
    },
  })
  styleObject;

  /** @name 正常模式 */
  @Prop({ type: Boolean, default: false }) normal;

  /** @name 是否有取消按钮 */
  @Prop({ type: Boolean, default: false }) closed;
  handleClosed() {
    this.$emit('close', this.data);
  }
  player = {};
  base = 48;
  baseColor = 'rgb(255, 255, 255)';
  baseRedColor = 'rgb(255, 0, 0)';
  id = `video${parseInt(Math.random() * 1000)}${Date.now()}`;
  width = 0;
  height = 0;
  async mounted() {
    this.resize();
    await this.calcRectFn();
    await delay(50);
    this.createVideo();
  }

  @Watch('url')
  async onUrlChange(value) {
    if (value) {
      this.isRecMode = false;
      this.createVideo();
    }
  }

  /** @description 摄像头是否能正常播放哦 */
  fetchMonitorIsNormal(url = 'https://open.ys7.com/api/lapp/device/capacity') {
    const formData = new FormData();
    formData.append('accessToken', this.accessToken);
    formData.append('deviceSerial', this.matchEzopenUrl(this.url).deviceSerial);
    return this.sendRequest(url, formData);
  }

  get dom() {
    try {
      let el = this.$refs.videoViewRef.parentNode;
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const array = Array.from(el.classList);
        const result = array.find(v => v === 'camera-video-box');
        if (result) {
          break;
        } else {
          el = el.parentNode;
        }
      }
      return el;
    } catch (error) {
      return this.$refs.videoViewRef.parentNode;
    }
  }
  async calcRectFn() {
    const rect = this.dom.getBoundingClientRect();
    this.width = rect.width;
    this.height = rect.height;
    await delay(100);
    this.handleResize(this.width, this.height);
  }
  resize() {
    document.addEventListener('fullscreenchange', async () => {
      if (!screenfull.isFullscreen && this.fullScreenStatus === 1) {
        this.fullScreenStatus = 0;
        this.handleResize(0, 0);
        await this.$nextTick();
        await delay(200);
        await this.calcRectFn();
      }
    });
    window.addEventListener(
      'resize',
      throttle(async () => {
        // 全屏阻止接下来的操作
        if (this.fullScreenStatus === 1) return false;
        if (this.fullScreenChange) return false;
        this.handleResize(0, 0);
        await this.$nextTick();
        await delay(200);
        await this.calcRectFn();
      }, 500),
    );
  }
  fullScreenStatus = 0;
  fullScreenChange = false;
  handleFullScreen() {
    const id = document.getElementById(this.id);
    this.fullScreenChange = true;
    if (screenfull.element === id && screenfull.isFullscreen) {
      screenfull.exit();
      this.player.cancelFullScreen();
      this.fullScreenStatus = 0;
      this.fullScreenChange = false;
    } else {
      screenfull.request(id);
      setTimeout(() => {
        const wraper = document.getElementById(`${this.id}`);
        wraper.style.width = '100%';
        wraper.style.height = '100%';

        this.fullScreenChange = false;
        this.player.fullScreen();
        this.fullScreenStatus = 1;
      }, 50);
    }
  }

  /** @name 标清链接 */
  get SDURL() {
    return this.url ? this.url.replace('.hd.live', '.live') : this.url;
  }
  /** @name 高清链接 */
  get HDURL() {
    return this.url;
  }
  handlePantile() {
    this.showPantile = !this.showPantile;
  }

  cameraDirection = 4;
  async setDirection(e, optType) {
    let container = this.$refs.ptzContainer.getBoundingClientRect();
    let containerCenterX = container.left + 41;
    let containerCenterY = container.top + 41;
    let eventX = e.x || e.changedTouches[0].clientX;
    let eventY = e.y || e.changedTouches[0].clientY;
    let left = eventX - containerCenterX;
    let top = eventY - containerCenterY;
    let direction = 0;

    if (Math.abs(left) > Math.abs(top)) {
      if (left > 0) {
        direction = 3;
      } else {
        direction = 2;
      }
    } else {
      if (top > 0) {
        direction = 1;
      } else {
        direction = 0;
      }
    }
    this.cameraDirection = optType === 'start' ? direction : 4;
    let url = this.player.env.domain + '/api/lapp/device/ptz/' + optType;
    let data = new FormData();
    data.append(
      'deviceSerial',
      this.matchEzopenUrl(this.player.url).deviceSerial,
    );
    data.append('channelNo', this.matchEzopenUrl(this.player.url).channelNo);
    data.append('speed', 1);
    data.append('direction', direction);
    data.append('accessToken', this.accessToken);
    await this.sendRequest(url, data);
  }
  sendRequest(url, data) {
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: 'POST',
        body: data,
      })
        .then(function(response) {
          return response.json();
        })
        .then(function(rt) {
          resolve(rt);
        })
        .catch(function(err) {
          reject(err);
        });
    });
  }
  /**
   * @description 匹配ezopen url 中的设备序列号， 通道， 验证码，是否高清 和类型
   * （萤石云播放地址）
   * @param {string} ezopenUrl ezopen协议的url
   * @returns {{ deviceSerial: string, channelNo: string, validCode: string, hd: boolean, type: string}}
   */
  matchEzopenUrl(ezopenUrl) {
    var deviceSerial = ezopenUrl.split('/')[3];
    var channelNo = ezopenUrl.split('/')[4].split('.')[0];
    var validCode =
      ezopenUrl.split('/')[2].split('@').length === 2
        ? ezopenUrl.split('/')[2].split('@')[0]
        : '';
    var hd = ezopenUrl.indexOf('.hd') !== -1;
    var type = ezopenUrl
      .split('/')[4]
      .split('.')
      [ezopenUrl.split('/')[4].split('.').length - 1].split('?')[0];

    if (type === 'rec' && ezopenUrl.indexOf('.cloud.rec') !== -1) {
      type = 'cloud.rec';
    }
    return {
      deviceSerial: deviceSerial,
      channelNo: channelNo,
      validCode: validCode,
      hd: hd,
      type: type,
    };
  }

  /** @name 是否标清 */
  boolIsSD = true;
  /** @name 监控链接 */
  link = this.SDURL;
  handleActionHDORSD() {
    if (this.boolIsSD) {
      this.handleOpenHD();
    } else {
      this.handleOpenSD();
    }
    this.player.changePlayUrl({
      url: this.link,
    });
  }
  handleOpenSD() {
    this.boolIsSD = true;
    this.link = this.SDURL;
  }
  handleOpenHD() {
    this.boolIsSD = false;
    this.link = this.HDURL;
  }

  handleResize(width = 0, height = 0) {
    try {
      this.player && this.player.reSize && this.player.reSize(width, height);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return false;
    }
  }

  handleMouseenter() {
    emitter.emit(CAMETA_VIEW_SHOW, {
      model: 'enter',
      data: this.data,
    });
  }
  handleMouseleave() {
    emitter.emit(CAMETA_VIEW_SHOW, {
      model: 'leave',
      data: this.data,
    });
  }

  // get videoLoading() {
  //   return this.player?.opt?.videoLoading;
  // }

  handleStopPlay() {
    this.player.stop();
  }
  handleStartPlay() {
    this.player.play({
      url: this.link,
    });
  }
  autoAction = true;
  handleAutoAction() {
    if (this.autoAction) {
      this.autoAction = false;
      this.handleStopPlay();
    } else {
      this.autoAction = true;
      this.handleStartPlay();
    }
  }

  handleCloseSound() {
    this.player.closeSound();
  }
  handleOpenSound() {
    this.player.openSound();
  }
  handleStartSave() {
    this.player.startSave(`${Date.now()}`);
  }
  handleStopSave() {
    this.player.stopSave();
  }
  handleCapturePicture() {
    this.player.capturePicture(`${Date.now()}`);
  }
  handleEnableZoom() {
    this.player.enableZoom();
  }
  handleCloseZoom() {
    this.player.closeZoom();
  }

  sound = false;
  handleSoundChange() {
    if (this.sound) {
      this.sound = false;
      this.handleCloseSound();
    } else {
      this.sound = true;
      this.handleOpenSound();
    }
  }
  actionSave = false;
  handleActionSaveChange() {
    if (this.actionSave) {
      this.actionSave = false;
      this.handleStopSave();
    } else {
      this.actionSave = true;
      this.handleStartSave();
    }
  }
  actionZoom = false;
  handleActionZoomChange() {
    if (this.actionZoom) {
      this.actionZoom = false;
      this.handleCloseZoom();
    } else {
      this.actionZoom = true;
      this.handleEnableZoom();
    }
  }

  actionHover = false;
  handleHoverEnter() {
    this.actionHover = true;
  }
  handleHoverOut() {
    this.actionHover = false;
  }
  async handleModeChange() {
    this.isRecMode = !this.isRecMode;
    this.createVideo();
  }
  showPantile = false;
  isPantile = false;
  player = null;
  isRecMode = false;
  async createVideo() {
    try {
      const res = await this.fetchMonitorIsNormal();
      if (res.result && res.result.code === '10002') {
        await refreshAccessToken({
          deviceSerial: this.matchEzopenUrl(this.url).deviceSerial,
        });
      }
      await this.$nextTick();
      if (this.player) {
        this.player.stop();
        this.player.destroy();
      }
      this.player = null;
      let params = {
        id: this.id,
        accessToken: this.accessToken,
        url: this.url.replace('.hd.live', this.isRecMode ? '.rec' : '.live'),
        template: (this.isRecMode && 'pcRec') || 'simple', // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
        audio: 0, // 是否默认开启声音 0 - 关闭 1 - 开启
        handleSuccess: () => {},
        handleError: () => {},
        width: this.width,
        height: this.height,
        bSupporDoubleClickFull: false,
        clickEventHandle: false,
        ...this.option,
      };
      this.player = new EZUIKit.EZUIKitPlayer(params);
      this.showPantile = false;
      this.isPantile = false;
      await delay(1000);
      this.isPantile =
        !this.isRecMode &&
        this.player?.capacity?.ptz_left_right === '1' &&
        this.player?.capacity?.ptz_top_bottom === '1';
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }
}
</script>
<style lang="less" module>
.wrapVideo {
  position: relative;
  overflow: hidden !important;

  .ezPtzWrap {
    z-index: 999999;
    position: absolute;
    right: 20px;
    top: calc(50% - 50px);
    width: 100px;
    height: 100px;
    .ezPtzContainer {
      position: relative;
      width: 80px;
      height: 80px;
      background: rgba(255, 255, 255, 0.8);
      box-shadow: 0 0 33px 4px rgb(0 0 0 / 15%);
      border: 1px solid rgba(255, 255, 255, 0.8);
      border-radius: 100%;
      cursor: pointer;
      overflow: hidden;
      user-select: none;
    }
    .ezPtzContainer0 {
      background-image: linear-gradient(
        180deg,
        rgb(29, 141, 216) 0%,
        rgba(100, 143, 252, 0) 30%
      );
    }
    .ezPtzContainer1 {
      background-image: linear-gradient(
        0deg,
        rgb(29, 141, 216) 0%,
        rgba(100, 143, 252, 0) 30%
      );
    }
    .ezPtzContainer2 {
      background-image: linear-gradient(
        90deg,
        rgb(29, 141, 216) 0%,
        rgba(100, 143, 252, 0) 30%
      );
    }
    .ezPtzContainer3 {
      background-image: linear-gradient(
        270deg,
        rgb(29, 141, 216) 0%,
        rgba(100, 143, 252, 0) 30%
      );
    }
    .ezPtzMain.center {
      width: 23px;
      height: 23px;
      background: #1890ff;
      border-radius: 100%;
      top: calc(50% - 12.3px);
      left: calc(50% - 12.3px);
      position: absolute;
    }
    .ezPtzIcon.top {
      width: 0;
      height: 0;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      border-bottom: 6px solid #333;
      position: absolute;
      display: inline-block;
      left: calc(50% - 3px);
      top: 2px;
      border-bottom-color: #1890ff;
    }
    .ezPtzIcon.left {
      width: 0;
      height: 0;
      border-top: 3px solid transparent;
      border-bottom: 3px solid transparent;
      border-right: 6px solid #333;
      position: absolute;
      display: inline-block;
      top: calc(50% - 3px);
      left: 2px;
      border-right-color: #1890ff;
    }
    .ezPtzIcon.bottom {
      width: 0;
      height: 0;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      border-top: 6px solid #333;
      position: absolute;
      display: inline-block;
      left: calc(50% - 3px);
      bottom: 2px;
      border-top-color: #1890ff;
    }
    .ezPtzIcon.right {
      width: 0;
      height: 0;
      border-top: 3px solid transparent;
      border-bottom: 3px solid transparent;
      border-left: 6px solid #333;
      position: absolute;
      display: inline-block;
      top: calc(50% - 3px);
      right: 2px;
      border-left-color: #1890ff;
    }
  }
  &:hover {
    .btn {
      display: inline-block;
    }
    .wrapPlayer {
      display: flex;
    }
  }
  .btn {
    display: none;
    width: 0.6rem;
    height: 0.3rem;
    border-radius: 0.04rem;
    line-height: 0.3rem;
    text-align: center;
    position: absolute;
    z-index: 999999;
    top: 0.04rem;
    right: 0.125rem;
    font-size: 0.16rem;
    color: #ffffff;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.5);

    &.disabled {
      background-color: rgba(128, 128, 128, 0.5);
    }

    &.normal {
      width: 60px;
      height: 30px;
      border-radius: 4px;
      line-height: 30px;
      top: 4px;
      right: 12.5px;
      font-size: 16px;
    }
  }
  .wrapPlayer {
    display: none;
    justify-content: space-between;
    position: absolute;
    z-index: 999999;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0.3rem;
    line-height: 0.3rem;
    padding: 0.12rem 0;
    box-sizing: content-box;
    background-color: rgba(0, 0, 0, 0.2);

    .iconBtn {
      display: inline-flex;
    }

    &.normal {
      height: 30px;
      line-height: 30px;
      padding: 12px 0;
    }

    &.disabled {
      background-color: rgba(128, 128, 128, 0.2);
    }

    .operation {
      height: 100%;
      display: inline-flex;
      align-items: center;
      padding: 0 0.1rem;

      .txt {
        font-size: 0.18rem;
        font-weight: 600;
        cursor: pointer;
        margin: 0 0.04rem;
        color: rgb(207, 207, 207);
        &.normal {
          margin: 0 10px;
          font-size: 18px;
          padding: 0 10px;
        }
      }
    }

    .icon {
      display: inline-block;
      margin: 0 0.1rem;
      margin-top: 0.04rem;
      font-size: 0.24rem;
      color: rgb(207, 207, 207);
      cursor: pointer;
      &.normal {
        margin: 0 10px;
        margin-top: 4px;
        font-size: 24px;
      }

      &.red {
        color: #f22;
      }
    }
  }
  .hideTimeline {
    :global {
      .ez-iframe-footer-container {
        display: none !important;
        & > div {
          display: none;
        }
      }
    }
  }
}
.hideTimeline {
  &:hover {
    :global {
      .ez-iframe-footer-container {
        display: none !important;
      }
    }
  }
}
.showTimeline {
  &:hover {
    :global {
      .ez-iframe-footer-container {
        margin-top: -150px !important;
        display: flex !important;
        z-index: 999 !important;
        > div:first-child {
          display: none !important;
        }
      }
    }
  }
}
</style>
