import request from '../request';
const serviceName = '/monitor/api';

/**
 * @name 绑定摄像头
 * @param { Object } data
 */
export function bindCamera(data) {
  return request(`${serviceName}/bindCamera`, {
    method: 'POST',
    body: data,
  });
}
/**
 * @name 解绑摄像头
 * @param { Object } data
 */
export function unBindCameraByIds(data) {
  return request(`${serviceName}/unBindCameraByIds`, {
    method: 'PUT',
    body: data,
  });
}
/**
 * @name 查看摄像头配置
 * @param { String } id
 */
export function getPreview(id) {
  return request(`${serviceName}/camera/getPreview/${id}`, {
    method: 'GET',
  });
}

/**
 * @name 刷新accessToken
 * @param { String } id
 */
export function refreshAccessToken(data) {
  return request(`/triascloud-iot-monitor/api/refreshAccessToken`, {
    method: 'GET',
    body: data,
  });
}
